.section{
    width: 100%;
    min-height: 100vh;
        
}

.section-2{
    padding-top: 50px ;
    width: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
}


.section-auto{
    width: 100%;
    height: auto;

}


.h-100{
    height: 100%;
}


.h-40{
    height: 100%;
}

.h-50{
    height: 50vh;
}