body{
    overflow-x: hidden;

}

.name-page{
    margin-left: 20px;    
}

.section-linea-investigacion{
    width: 100vw;
    min-height: 100vh;
    background-color: whitesmoke;
}

.img-article-container{
    width: 80%;
}

.linea-side > a{
    text-decoration: none;
    color: aliceblue;
    transition: 0.3s;
}

.linea-side > a:hover{
    color: #0dcaf0;
}

.main-article-container{
    margin-bottom: 20px;
}
.container-img-card-line {
    overflow: hidden;
    border-radius: 5px 0 0 5px;

}

.container-img-card-line > img{
    transform: scale(1.4);
}

.card-alumno-modal{
    cursor: pointer;
    transition: 0.5s;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.25));

}


.blog-text{
    text-align: justify;
}

.hidden{
    display: none;
}


.img-card-modal{
    max-width: 150px;
    
}

/* style={{height: '225px'}} */ 

.card-alumno-modal {
    height: 225px !important;
}
.p-33
{
    text-align: center;
    padding: 10px;

}
.p-44
{
    font-size: 15px;
    text-align: justify;
    margin-bottom: 5px;
    margin-right: 10px;
   
}

.img-container-modal {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-container-modal > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.text-indent-0{
    text-indent: 0 !important;
    transition: 0.3s;

}

.text-indent-0:hover{
    color:  rgb(13, 58, 129)!important;
}

.card-alumno-modal:hover{
    box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.052),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.062),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.064),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.062),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.06),
  100px 100px 80px rgba(0, 0, 0, 0.07)
;
}

@media (max-width:480px) {
    .card-alumno-modal{
        height: 400px !important;
    }
    
}