
.texto-cita{
    font-size: 20px;
    text-align: justify;
    font-weight: 600;
}

.cita-autor{
    font-size: 18px;
    font-style: italic;
}


