

.bg-landing{
    background-color: black;
    width: 100%;
    height: 100%;
    background-image: url('../assets/stacked-peaks-haikei.svg');
    position: absolute;
    z-index: 1;
}

.conent-landing{
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 2 !important ;
}

.header-landing{
    font-size: 80px;
}