.nav-link.active{
    background-color: #041735 !important;
    color: aliceblue !important;
}

.nav-link{
    color: #041735 !important;
}

.tab-content{
    background-color: aliceblue !important;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));  
    padding: 10px;
}



.tab-pane{
    background-color: white !important;
}

.tab-content{
    background-color: white !important;
}

.lista-patentes{
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));  
    font-size: 18px;
}

.lista-patentes li {
    padding: 10px;
}

.badge{
    transition: 0.3s !important;
}

.badge:hover{
        background-color: #041735 !important;
}

.item-patente{
    transition: 0.3s;
}

.item-patente:hover{
    background-color: #a5b5ce !important;
}



.paper-text{
    text-decoration: none;
    color: #041735;
    font-size: 20px !important;
    padding: 10px;
    transition: 0.3s;
    cursor: pointer;
}
.paper-text:hover{  
    text-decoration: underline;
    color: #0e49a8;
    font-size: 20px !important;
    padding: 10px;

}


.container-publicaciones{
    height: 600px;
    overflow: scroll;
    overflow-x:hidden ;
    scroll-behavior: smooth;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));  

}

.container-publicaciones::-webkit-scrollbar {
    width: 10px;
  }

.container-publicaciones::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
.container-publicaciones::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

.btn-paper{
    background-color: #041735 !important;
    color: whitesmoke !important;
}

.btn-paper:hover{
    background-color: #061f49 !important;
    color: whitesmok
}

.card-tesis-container{
    border: none !important;
    border-radius: 0!important;
    box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.029),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.038),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.043),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.047),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.053),
        100px 100px 80px rgba(0, 0, 0, 0.07);
}

.header-tesis-card{
    color: #041735;
}
