

.container-index{
    width: 90% !important;
    height: 100%;
    margin: 20px;
    padding: 50px;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));  
    background-color: whitesmoke;
    }

.button-index{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-icon{
    width: 5rem;
    height: 5rem;  
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));
}
.container-icon:hover{
    background-color:  rgb(7, 38, 88);
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.5));
}

.container-icon:hover > i{
    animation: shake 1s;

}

.icon-indice{
    color: aliceblue;
    font-size: 50px;
    margin: -5px;

}
.tittle-dot{
    font-size: 18px;
    color: rgba(4, 23, 53, 1);
    font-weight: 500;
}

.color-1{
  background-color: #041735 !important ;
}
.color-2{
  background-color: #1D2E49;

}
.color-31{
  background-color: #36455D ;

}
.color-4{
  background-color: #4F5D72 ;

}
.color-5{
  background-color: #687486 ;

}
.color-6{
  background-color: #818B9A ;

}
.color-7{
  background-color: #B4B9C2 ;

}

@media (max-width: 700px){
  #container-index-media  {
    display: none !important;
  } 

}

@media (max-width: 1025px) {
  .tittle-dot{
    font-size: 15px !important;
  }
  
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

