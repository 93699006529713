.container-fotosG{
    
    height: 80vh;
}

.container-imagen-grupal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.carousel-container2{
    max-height: 100%;
}