
#id-img-evenp-2{
    transform:  scale(1.2);
    
}

#id-img-evenp-8{
    transform:  scale(1.2);
}

#id-img-evenp-10{
    transform:  scale(1.2);

}

#id-img-evenp-18{
    transform:  scale(1.2);

}

.container-list{
    width: 100% ;
    min-height: 600px;  
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
}

.carousel-container{
    width: 100%;
    height: 100%;
    padding: 50px;
}

.foto-eventop{
    height: px;
    object-fit: cover;
    border-radius: 50%;
    margin: 20px;
}

.card.d-flex{
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);

}

.imgen-card-container{
    width: 100%;
    background-color: #173504;
    border-radius: 15px;

}

.imgen-content{
    height: 225px;
    width: 225px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

}

.imgen-content > img{
    height: 260px;
    width: 260px;
    transform:  scale(1.3);

}

.card-eventop{
    height: 550px !important;
}

.h-225{
    height: 225px;
    width: 225px;
}

.name-evenp{
    color: #36455D;
    font-size: 25px;
    margin-top: 10px;
}

.linea-evenp{
    color: #818B9A;
    font-size: 20px;
}

.linea-inv-text{
    color: #818B9A;
    margin-top: 5px;
}