.separator-container{
    margin-top: 20px;
    margin-bottom: 20px;

}


.line-separator{
    border-top: 2px solid ;

}

.header-separator-text{
    font-size: 50px;
    
}