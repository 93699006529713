

.card-colaborator{
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    min-height: 10rem;
    height: 100% !important;
}

.img-colaborator{
    margin: 5px;
}