

.picture-home{
    width: 18rem;
    overflow: hidden;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.35));  
}

.foto-cv{
    object-fit: contain;
    width: 20rem;
    border-radius: 10px;
    overflow: hidden;
}

.information-container{
    width: 100%;
    min-height: 500px;
    padding: 20px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 35%);
    border-radius: 10px;
    background-color: whitesmoke;
    
}

.text-justify{
    text-align: justify;
}

.semblaza{
    padding: 10px;
    font-size: 20px;
}
.semblaza > h4{
    font-size: 25px;
    font-weight: 800;
}

.higth-ligth-icon{
    
    height: 50px;
    width: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    font-size:30px;    
}

.higth-ligth-icon > i{
    margin-left: -1px;
}

.higth-ligth-text{
    height: 50px;  
}

.cv-btn{
    margin-top: 10px;
}


.color-blue{
    background-color: #041735;

}

.color-ligth-blue{
    background-color: #4F5D72;
    
}

.color-green{
    background-color: #173504;
    
}   