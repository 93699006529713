



.card-linea-container{
    width: 100%;
    height: 300px;
    background-color: rgba(29, 46, 73, 1);
    color: aliceblue;
    border-radius:10px ;
    padding: 20px;
    display: flex;  
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.5s;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, .5));
    cursor: pointer;
}


.card-linea-container:hover{
    background-color: rgb(42, 67, 107);
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, .8));


}
.icon-card-linea{
    width: 80px;   
}

.icon-card-linea > img{
    width: 80px;
    color: aliceblue;
}

.name-of-line{
    font-size: 22px;
    font-weight: 400;
    margin-top: 20px;
}


.color-1{
    background-color: rgba(29, 46, 73, 1);
}
.color-2{
    background-color: rgba(79, 93, 114, 1);
}
.color-3{
    background-color: rgba(23, 53, 4, 1);
}

